import React, { createContext, useContext, useState } from "react"

const HeaderContext = createContext()

function HeaderContextProvider({ children }) {
  const [showSearch, setShowSearch] = useState(false)

  return (
    <HeaderContext.Provider value={{ showSearch, setShowSearch }}>
      {children}
    </HeaderContext.Provider>
  )
}

function useHeaderContext() {
  const context = useContext(HeaderContext)

  if (context === undefined) {
    throw new Error(
      "useHeaderContext must be used within a HeaderContextProvider"
    )
  }

  return context
}

export { HeaderContextProvider, useHeaderContext }
