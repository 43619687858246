// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-artist-js": () => import("/opt/build/repo/src/templates/Artist.js" /* webpackChunkName: "component---src-templates-artist-js" */),
  "component---src-templates-news-article-js": () => import("/opt/build/repo/src/templates/NewsArticle.js" /* webpackChunkName: "component---src-templates-news-article-js" */),
  "component---src-pages-about-js": () => import("/opt/build/repo/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("/opt/build/repo/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-employment-js": () => import("/opt/build/repo/src/pages/employment.js" /* webpackChunkName: "component---src-pages-employment-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("/opt/build/repo/src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-roster-js": () => import("/opt/build/repo/src/pages/roster.js" /* webpackChunkName: "component---src-pages-roster-js" */),
  "component---src-pages-search-js": () => import("/opt/build/repo/src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-terms-js": () => import("/opt/build/repo/src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

