import React, { createContext, useContext, useReducer } from "react"

// Adapted from https://kentcdodds.com/blog/how-to-use-react-context-effectively

const RosterCategoryContext = createContext()
const RosterCategoryDispatchContext = createContext()

const rosterCategoryActions = {
  INIT_FROM_URL: "INIT_FROM_URL",
  SELECT_CATEGORY: "SELECT_CATEGORY",
  SELECT_SUBCATEGORY: "SELECT_SUBCATEGORY",
}

function updateUrlParameters(state) {
  const selectedCategory = state.selectedCategory
    ? encodeURIComponent(state.selectedCategory)
    : ""
  const selectedSubcategory = state.selectedSubcategory
    ? encodeURIComponent(state.selectedSubcategory)
    : ""

  let url = `/roster`

  // If the user selected "all" - revert to a default URL state and bail out
  if (selectedCategory === "all") {
    window.history.pushState({}, "", `${url}?category=all`)

    return
  }

  if (selectedCategory) {
    url += `?category=${selectedCategory}`
  }

  if (selectedSubcategory) {
    url += `&subcategory=${selectedSubcategory}`
  }

  window.history.pushState({}, "", url)
}

function rosterCategoryReducer(state, action) {
  let result

  switch (action.type) {
    case rosterCategoryActions.INIT_FROM_URL:
      // We actually don't want to trigger another entry to the browser history here, so
      // we return from this case instead of `break`ing and updating the URL below
      return {
        ...state,
        selectedCategory: action.payload.category,
        selectedSubcategory: action.payload.subcategory,
      }

    case rosterCategoryActions.SELECT_CATEGORY:
      result = {
        ...state,
        selectedCategory: action.payload,
        selectedSubcategory: null,
      }

      break
    case rosterCategoryActions.SELECT_SUBCATEGORY:
      result = {
        ...state,
        selectedSubcategory: action.payload,
      }

      break
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }

  updateUrlParameters(result)

  return result
}

function RosterCategoryProvider({ children }) {
  const [state, dispatch] = useReducer(rosterCategoryReducer, {
    selectedCategory: "all",
    selectedSubCategory: null,
  })

  return (
    <RosterCategoryContext.Provider value={state}>
      <RosterCategoryDispatchContext.Provider value={dispatch}>
        {children}
      </RosterCategoryDispatchContext.Provider>
    </RosterCategoryContext.Provider>
  )
}

function useRosterCategoryState() {
  const context = useContext(RosterCategoryContext)

  if (context === undefined) {
    throw new Error(
      "useRosterCategoryState must be used within a RosterCategoryProvider"
    )
  }

  return context
}

function useRosterCategoryDispatch() {
  const context = useContext(RosterCategoryDispatchContext)

  if (context === undefined) {
    throw new Error(
      "useRosterCategoryDispatch must be used within a RosterCategoryProvider"
    )
  }

  return context
}

export {
  RosterCategoryProvider,
  useRosterCategoryState,
  useRosterCategoryDispatch,
  rosterCategoryActions,
}
