import React from "react"

import { RosterCategoryProvider } from "./src/components/context/RosterCategoryContext"
import { HeaderContextProvider } from "./src/components/context/HeaderContext"

export const onClientEntry = () => {
  // Object-fit/Object-position polyfill for gatsby-image (IE)
  const testImg = document.createElement(`img`)
  if (
    typeof testImg.style.objectFit === `undefined` ||
    typeof testImg.style.objectPosition === `undefined`
  ) {
    require(`object-fit-images`)()
  }
}

export const wrapRootElement = ({ element }) => {
  return (
    <RosterCategoryProvider>
      <HeaderContextProvider>{element}</HeaderContextProvider>
    </RosterCategoryProvider>
  )
}
